<template>
  <v-navigation-drawer
    v-model="open"
    v-bind="$attrs"
    :class="{ 'bg-grey-lighten-5': !isDark, 'bg-grey-darken-3': isDark }"
    width="auto"
    temporary
  >
    <v-card class="mx-4 my-2" min-width="80vw" elevation="2">
      <v-list-item title="John Leider">
        <template #prepend>
          <v-avatar
            size="50"
            rounded="circle"
            :color="isDark ? 'grey-darken-3' : 'grey-lighten-2'"
          >
            <!--  https://randomuser.me/api/portraits/men/78.jpg -->
          </v-avatar>
        </template>
        <template #append>
          <v-badge overlap :content="itemsCount" max="10" bordered>
            <v-avatar
              @click="toggleCart"
              size="40"
              rounded="circle"
              :color="isDark ? 'grey-darken-2' : 'grey-lighten-3'"
            >
              <nuxt-icon name="bag" />
            </v-avatar>
          </v-badge>
        </template>
      </v-list-item>
    </v-card>

    <v-list nav>
      <v-list-item
        v-for="(link, i) in menuLinks"
        :key="`menu-link-${i}`"
        :title="link.title"
        :to="link.to"
      >
        <template #prepend>
          <nuxt-icon :name="link.icon" class="mr-4" />
        </template>
        <!-- <v-list-item-title>{{ link.title }}</v-list-item-title> -->
      </v-list-item>

      <v-divider class="my-4" />
      <v-list-item
        v-for="(link, i) in resourceLinks"
        :key="`resource-link-${i}`"
        :title="link.title"
        :prepend-icon="link.icon"
        :to="link.to"
        :href="link.href"
        :target="link.target"
      />
    </v-list>
    <template #append>
      <div class="pa-4">
        <v-btn class="w-full" variant="tonal" size="large">Logout</v-btn>
      </div>
      <v-divider />
      <div class="text-caption">
        <theme-switch variant="text" size="small" />
      </div>
      <v-divider />
      <div class="text-caption px-4 py-1">
        <span
          >© {{ new Date().getFullYear() }} Orie Commerce. All rights
          reserved.</span
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeSwitch from "../../elements/ThemeSwitch.vue";
import { useThemeStore } from "~~/stores/theme";
import { storeToRefs } from "pinia";

export default defineComponent({
  props: {
    modelValue: { type: Boolean },
  },
  components: { ThemeSwitch },
  setup() {
    const config = useRuntimeConfig();
    const { itemsCount } = useCart();
    const theme = useThemeStore();

    const { isDark } = storeToRefs(theme);
    const { toggle: toggleCart } = useInstantCart();

    return {
      brochureUrl: config.public.links.brochure,
      itemsCount,
      toggleCart,
      isDark,
    };
  },
  data: () => ({
    menuLinks: [
      { title: "Orders", icon: "box-open" },
      { title: "Notifications", icon: "bell" },
      { title: "My Lists", icon: "clipboard-list" },
      { title: "Messages", icon: "envelope-outlined" },
      { title: "Account", icon: "user" },
    ],
  }),
  computed: {
    open: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
    resourceLinks() {
      return [
        {
          title: "For business",
          href: this.brochureUrl,
          icon: "sell",
          target: "_blank",
        },
        {
          title: "Terms of Use",
          icon: "gavel",
          to: { name: "legal-handle", params: { handle: "terms" } },
        },
        {
          title: "Privacy Policy",
          icon: "policy",
          to: { name: "legal-handle", params: { handle: "privacy" } },
        },
        // {
        //   title: "Sales and Refunds",
        //   to: { name: "legal-handle", params: { handle: "sales" } },
        // },
      ];
    },
  },
});
</script>
