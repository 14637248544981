<template>
  <v-app-bar
    class="w-full"
    v-bind="$attrs"
    location="top"
    :density="mobile ? 'compact' : 'default'"
    :absolute="absolute"
    flat
    border
  >
    <v-row class="hidden md:block" no-gutters justify="space-between">
      <v-col cols="auto">
        <v-btn @click.stop="drawer = !drawer" icon="account_circle" />
      </v-col>
      <v-col cols="auto">
        <v-row class="h-full" align-content="center" no-gutters>
          <router-link to="/">
            <themed-logo size="35" />
          </router-link>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-btn @click="toggleInfo" icon="grid_view" />
      </v-col>
    </v-row>
    <v-container class="hidden md:block w-full">
      <v-row justify="space-between" no-gutters>
        <v-col>
          <nuxt-link to="/">
            <v-card-actions class="pa-0">
              <themed-logo size="40" />
              <v-toolbar-title class="ml-2">Orie</v-toolbar-title>
            </v-card-actions>
          </nuxt-link>
        </v-col>
        <v-col cols="auto">
          <v-row align-content="center" class="h-full" no-gutters>
            <v-responsive width="500">
              <desktop-search />
            </v-responsive>
          </v-row>
        </v-col>
        <v-col>
          <v-card-actions class="pa-0">
            <v-spacer />
            <v-btn
              :href="brochureUrl"
              target="_blank"
              variant="text"
              class="mr-2"
              size="small"
              rounded
            >
              For business
            </v-btn>
            <v-divider vertical class="my-4" />
            <v-btn size="small" icon="account_circle" />
            <cart-btn size="small" />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
  <mobile-drawer v-model="drawer" />
  <action-dialog v-model="infoMenu" max-height="calc(100vh - 200px)">
    <v-row
      style="height: 100px"
      justify="center"
      align-content="center"
      no-gutters
    >
      <logo size-="30" />
    </v-row>
    <v-list>
      <v-list-item
        v-for="item in infoItems1"
        :key="item.title"
        :title="item.title"
        :subtitle="item.subtitle"
        :to="item.to"
      >
        <template #prepend>
          <v-icon :icon="item.icon" />
        </template>
      </v-list-item>
      <v-divider class="ma-2" />
      <v-list-item
        v-for="item in infoItems2"
        :key="item.title"
        :title="item.title"
        :subtitle="item.subtitle"
        :to="item.to"
      >
        <template #prepend>
          <v-icon :icon="item.icon" />
        </template>
      </v-list-item>
      <v-divider class="ma-2" />
      <v-list-item
        v-for="item in infoItems3"
        :key="item.title"
        :title="item.title"
        :to="item.to"
        density="compact"
      />
    </v-list>
  </action-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemedLogo from "~~/components/elements/ThemedLogo.vue";
import MobileDrawer from "../modules/MobileDrawer.vue";
import CartBtn from "../modules/CartBtn.vue";
import ActionDialog from "~~/components/dialogs/ActionDialog.vue";
import Logo from "~~/components/global/Logo.vue";
import DesktopSearch from "~~/components/elements/search/DesktopSearch.vue";

export default defineComponent({
  name: "DefaultHeader",
  props: {
    absolute: { type: Boolean, default: false },
  },
  components: {
    ThemedLogo,
    MobileDrawer,
    CartBtn,
    ActionDialog,
    Logo,
    DesktopSearch,
  },
  setup() {
    const mobile = useMobile();
    const config = useRuntimeConfig();
    const route = useRoute();

    const drawer = ref(false);
    const infoMenu = ref(false);

    const toggleInfo = () => (infoMenu.value = !infoMenu.value);
    watch(route, () => (infoMenu.value = false));

    return {
      mobile,
      drawer,
      brochureUrl: config.public.links.brochure,
      infoMenu,
      toggleInfo,
    };
  },
  data: () => ({
    infoItems1: [
      {
        title: "Recipes",
        subtitle: "Browse African recipes",
        icon: "restaurant",
        to: { name: "recipes" },
      },
      {
        title: "Saved items",
        subtitle: "Saved products, events, recipes, etc.",
        icon: "bookmark_border",
        to: { name: "profile-lists" },
      },
      {
        title: "Ask a question",
        subtitle: "Ask us anything. Find out more about communities around you",
        icon: "help_outline",
        to: { name: "help-ask" },
      },
      {
        title: "Businesses",
        subtitle: "Find local businesses",
        icon: "work_outline",
        to: { name: "businesses" },
      },
      {
        title: "Blog",
        subtitle: "Orie updates, announcements and press",
        icon: "campaign",
        // to: { name: "blog" },
      },
    ],
    infoItems2: [
      {
        title: "Help centre",
        subtitle: "Get help from our support team",
        icon: "support",
        to: { name: "help" },
      },
      {
        title: "Returns",
        subtitle: "Get help from our support team",
        icon: "swap_horiz",
        to: { name: "help-returns" },
      },
      {
        title: "Feedback",
        subtitle: "Leave us a feedback",
        icon: "contact_support",
        to: { name: "help-feedback" },
      },
    ],
  }),
  computed: {
    infoItems3() {
      return [
        { title: "For business", to: `//${this.brochureUrl}` },
        { title: "Who we are", to: { name: "about" } },
        // {
        //   title: "Terms of use",
        //   to: { name: "legal-handle", params: { handle: "terms" } },
        // },
        // {
        //   title: "Privacy policy",
        //   to: { name: "legal-handle", params: { handle: "privacy" } },
        // },
        { title: "Legal", to: { name: "legal" } },
      ];
    },
  },
});
</script>
